
  var editorScriptEntry = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://c5095236fd2e4b8fae9ed6d73cdd81a3@sentry.wixpress.com/4856',
        id: 'c5095236fd2e4b8fae9ed6d73cdd81a3',
        projectName: 'restaurants-order-tracker',
        teamName: 'orders-livesite',
        errorMonitor: true,
      };
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hu","id","it","ja","ko","lt","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"tracker.editor.page.title":"Order Tracking Page","tracker.page.order-id.empty-state":"None","tracker.page.pickup-details.title":"Pickup Details","tracker.page.delivery-details.title":"Delivery Details","tracker.page.delivery-details.address.title":"Delivery to:","tracker.page.pickup-details.address.title":"Pickup from:","tracker.page.delivery-details.due-by.title":"Delivery time:","tracker.page.pickup-details.due-by.title":"Pickup time:","tracker.page.order-details.order-note.title":"Order notes:","tracker.status.order-pending.title":"Pending","tracker.status.order-accepted.title":"Accepted","tracker.status.order-ready.title":"Ready","tracker.status.order-out-for-delivery.title":"Out For Delivery","tracker.status.order-fulfilled.title":"Fulfilled","tracker.status.order-canceled.title":"Canceled","tracker.status.order-unknown.title":"Unknown","tracker.status.order-pending.description":"Your order was received and it’s status will be updated here","tracker.status.order-accepted.description":"Your order was accepted and will be prepared accordingly.","tracker.status.order-ready.description":"Your order is ready","tracker.status.order-out-for-delivery.description":"Your order is on it’s way","tracker.status.order-fulfilled.description":"Enjoy your order!","tracker.status.order-canceled.description":"Your order was canceled","tracker.status.order-unknown.description":"Unknown"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'restaurants-order-tracker',
        appDefId: 'e329a4ab-e1b2-4268-9cc6-ac6f77ec6767',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Map/editor.controller.ts');

      const model_0 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Map/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-dg4og";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-dg4og": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-dg4og", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Tracker/editor.controller.ts');

      const model_1 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/Tracker/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-x91qr";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-x91qr": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-x91qr", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/trackingPage/editor.controller.ts');

      const model_2 = require('/home/builduser/work/1cc725a297056c11/packages/restaurants-order-tracker/src/components/trackingPage/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-euxz6";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-euxz6": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-euxz6", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
