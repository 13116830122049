import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { TRACKER_HELP_ID } from '../../constants/gfpp';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.behavior().set({ removable: false });

  widgetBuilder
    .set({
      displayName: 'Tracking Page Widget',
      nickname: 'TrackingPageWidget',
    })
    .gfpp()
    .set('help', { id: TRACKER_HELP_ID })
    .set('mainAction1', { behavior: 'DEFAULT' });

  widgetBuilder.configureConnectedComponents(
    'tracker2',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder
        .gfpp()
        .set('design', {
          behavior: 'HIDE',
        })
        .set('mainAction1', { behavior: 'DEFAULT' })
        .set('help', { id: TRACKER_HELP_ID });
    },
  );
};
