import { EditorReadyFn, TFunction } from '@wix/yoshi-flow-editor';
import { handleInstall } from './install/install';
import { EditorType } from '@wix/platform-editor-sdk';

export const editorReadyFn: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  const t = flowAPI.translations.t as TFunction;
  if (options.firstInstall) {
    const isResponsive = options.origin.type === EditorType.Responsive;
    await handleInstall(editorSDK, appDefId, t, isResponsive);
  }
};
