import { EditorReadyFn } from '@wix/yoshi-flow-editor';
import { editorReadyFn } from './editor-app/editorReady';
import { AppExposedApis, EditorSDK } from '@wix/platform-editor-sdk';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';

const OLO_APP_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60';

export const editorReady: EditorReadyFn = editorReadyFn;

export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};

export const exports = (editorSDK: EditorSDK): AppExposedApis => ({
  editor: {
    removeAppCompleted: ({ appDefinitionId }) => {
      if (appDefinitionId === OLO_APP_ID) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      }
    },
  },
});
